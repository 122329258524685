import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpHttpInterceptor } from 'abp-ng2-module';

import * as ApiServiceProxies from './service-proxies';

@NgModule({
    providers: [
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.ConfigurationServiceProxy,
        ApiServiceProxies.EmployeeServiceProxy,
        ApiServiceProxies.CustomerServiceProxy,
        ApiServiceProxies.ProductCategoryServiceProxy,
        ApiServiceProxies.ProductServiceProxy,
        ApiServiceProxies.CommonServiceProxy,
        ApiServiceProxies.RawMaterialServiceProxy,
        ApiServiceProxies.ExpenseServiceProxy, 
        ApiServiceProxies.CheckServiceProxy, 
        ApiServiceProxies.ProductionServiceProxy,
        ApiServiceProxies.SaleServiceProxy,
        ApiServiceProxies.ProductionServiceProxy,
        ApiServiceProxies.DistributionServiceProxy,
        ApiServiceProxies.BasketServiceProxy,
        ApiServiceProxies.ReportServiceProxy,
        ApiServiceProxies.HomeServiceProxy,
        ApiServiceProxies.CreditServiceProxy,
        ApiServiceProxies.TaxRateServiceProxy,
        ApiServiceProxies.GeneralSettingServiceProxy,
        ApiServiceProxies.FrequentlyUsedProductServiceProxy,
        ApiServiceProxies.ServiceProxy,
        ApiServiceProxies.PaymentMethodServiceProxy,
        ApiServiceProxies.GeneralSettingUIServiceProxy,
        ApiServiceProxies.CashServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
